.text-page {
    h1 {
        width: 100%;
    }

    h2,
    h3,
    h4 {
        padding-top: var(--spacing-s);
        font-size: var(--font-l);
    }
    
    .contents {
        column-count: 1;
    }

    ul {
        margin-left: 1.5em;
        li {
            list-style-type: disc;
        
        }
    }
}