.modal-wrapper {
    position: fixed;
    z-index: 4;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(#000000, 0.5);
    overflow: hidden;
    .modal {
        width: 300px;
        z-index: 5;
        height: 570px;
        background-color: white;
        opacity: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: var(--spacing-m) var(--spacing-m);
        .logo {
            width: 100%;
            margin-bottom: var(--spacing-m);
            height: 50px;
        }
        & > form {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            & > h3 {
                width: 100%;
                padding-top: 5px;
                &.half {
                    width: 50%;
                }
                &.register {
                    padding-top: 0;
                }
            }
            & > input {
                font-size: var(--font-s);
                width: 100%;
                margin: var(--spacing-xs) 0;
                padding: var(--spacing-xs) var(--spacing-s);
                text-transform: uppercase;
                border: solid black 1px;
                color: black;
                &.half {
                    width: calc(50% - var(--spacing-xs));
                }
            }
        }
        .error {
            font-family: futura-pt-bold, sans-serif;
            font-size: var(--font-s);
            font-weight: 700;
            margin-top: -15px;
        }
        .success {
            position: relative;
            top: 33%;
            transform: translateY(-50%);
        }
        .disclaimer {
            width: 100%;
            margin: 0;
            margin-top: var(--spacing-xs);
            p {
                margin: 0;
                font-size: var(--font-s);
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                    border: none;
                }
            }
        }
    }
}


@media (max-width: 767px) {
    .modal-wrapper {
        .modal{
            width: 100%;
            height: 100%;
        }
    }
}