:root {
    --font-xxl: 32px;
    --font-xxl: 27px;
    --font-xl: 24px;
    --font-l: 20px;
    --font-ml: 18px;
    --font-m: 15px;
    --font-s: 12px;
    --font-xs: 8px;

    --spacing-xl: 80px;
    --spacing-l: 60px;
    --spacing-m: 40px;
    --spacing-s: 20px;
    --spacing-xs: 10px;
    --spacing-xxs: 5px;

    --container-size: 1280px;
}