* {
    box-sizing: border-box;
    z-index: 1;
}

.container {
    position: relative;
    display: block;
    width: 100%;
    // max-width: var(--container-size);
    margin: auto;
    padding: var(--spacing-m);
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .half {
            width: 50%;
        }
    }
}

.logo {
    color: transparent;
    user-select: none;
    height: 40px;
    background-image: url(../../img/logo.svg);
    background-repeat: no-repeat;
    z-index: 15;
    cursor: pointer;
    &.black {
        filter: invert(100%);
    }
    &.small {
        height: 150%;
        width: 120px;
        transform: translateY(-2px);
        font-size: var(--font-m);
    }
}

main {
    min-height: calc(100vh - var(--spacing-xl) - var(--spacing-xl) - var(--spacing-xl) - 1em);
}

header,
footer {
    z-index: 3;
    &.black {
        color: black;
        input[type="search"] {
            border-color: black;
            color: black;
        }
        .logo,
        .instagram,
        .burger {
            filter: invert(100%);
        }
    }
    &.white {
        color: white;
        span::after {
            filter: invert(100%);
        }
    }
}

footer {
    height: 100%;
    margin-top: calc(var(--spacing-xl) + var(--spacing-xl)) !important;
    nav {
        height: 1em;
        font-size: var(--font-m);
        ul {
            display: flex;
            text-align: left;
            width: calc(50% - 80px);
            &.right {
                justify-content: flex-end;
                text-align: right;
                li {
                    margin-left: var(--spacing-s);
                }
            }
            &.left {
                margin-right: var(--spacing-s);
                li {
                    margin-right: var(--spacing-s);
                }
            }
            .instagram {
                cursor: pointer;
                display: inline-block;
                width: var(--spacing-s);
                height: var(--spacing-s);
                background-image: url(../../img/icons_Insta_Button_weiß.svg);
                background-size: 180%;
                background-position: 50% 60%;
                &:hover {
                    border-bottom: none;
                }
            }
        }
    }
}

nav {
    position: relative;
    text-align: right;
    font-size: var(--font-l);
    & > * {
        vertical-align: top;
    }
    & > a {
        margin-right: var(--spacing-s);
    }
    & > span {
        & > input[type="search"] {
            vertical-align: top;
            border: none;
            border-bottom: solid white 2px;
            color: white;
            width: 80px;
            background: none;
            transform: translateY(-10px);
            margin-right: var(--spacing-s);
        }
        &.search::after {
            transform: translateY(-10px);
            content: "";
            display: inline-block;
            left: calc(0px - var(--spacing-s));
            position: relative;
            width: var(--spacing-m);
            height: var(--spacing-m);
            background-image: url(../../img/search.svg);
            background-size: 100%;
        }
    }
    & > .instagram {
        cursor: pointer;
        display: inline-block;
        width: var(--spacing-s);
        height: var(--spacing-s);
        background-image: url(../../img/icons_Insta_Button_weiß.svg);
        background-size: 200%;
        background-position: 50% 45%;
        &:hover {
            border-bottom: none;
        }
    }
    & > .burger {
        cursor: pointer;
        display: inline-block;
        width: var(--spacing-s);
        height: var(--spacing-s);
        background-image: url(../../img/burger.svg);
        background-size: 700%;
        background-position: 48% 51.5%;
    }
    & > .menu {
        position: absolute;
        display: block;
        right: 0;
        top: 1.5em;
        display: none;
        &.active {
            display: block;
        }
        & > a {
            width: 100%;
            user-select: none;
            line-height: 1.5;
        }
    }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

form {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-appearance: none;
    width: 100%;
    h3 {
        width: 100%;
        padding-top: 5px;
        &.half {
            width: 50%;
        }
        &.register {
            padding-top: 0;
        }
    }
    input {
        -webkit-appearance: none;
        border-radius: 0;
    }
    .input-group {
        font-size: var(--font-s);
        width: 100%;
        margin: var(--spacing-xs) 0;
        padding: var(--spacing-xs) var(--spacing-s);
        text-transform: uppercase;
        border: solid black 1px;
        color: black;
        &.half {
            width: calc(50% - var(--spacing-xs));
        }
    }
}

.CookieConsent {
    button {
        margin: var(--spacing-s) auto;
        margin-bottom: 0;
        padding: var(--spacing-xxs) var(--spacing-s);
        border: 2px solid black;
        background-color: white;
        color: black;
        -webkit-appearance: none;
        text-transform: uppercase;
        cursor: pointer;
        transition: 200ms 100ms;
        width: 100%;
        &:active {
            background-color: black;
            color: white;
            transition: 0ms;
        }
    }
}

@media (max-width: 980px) {
    header {
        z-index: 3;
        &.black {
            .instagram {
                filter: none;
            }
        }
    }
    .container {
        padding: var(--spacing-m) var(--spacing-s);
        .row {
            nav {
                position: absolute;
                top: var(--spacing-s);
                right: 0;
                &.half {
                    width: 180px;
                }
                &.active {
                    background-color: white;
                    color: black;
                }
                & > .burger {
                    position: relative;
                    display: inline-block;
                    right: 0;
                    margin: var(--spacing-s);
                    height: var(--spacing-m);
                    width: var(--spacing-m);
                    background-size:600%;
                    z-index: 2;
                    cursor: pointer;
                    user-select: none;
                    &.active {
                        filter: invert(1);
                    }
                }
                & > .menu {
                    padding: var(--spacing-s);
                    padding-bottom: var(--spacing-l);
                    background-color: white;
                    color: black;
                    width: 100%;
                    position: absolute;
                    top: var(--spacing-l);
                    line-height: 2;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .layout {
        &.menu-open {
            .container {
                .row {
                    .logo {
                        filter: invert(100%);
                    }
                }
            }
        }
        footer {
            padding: 0;
         nav {
            display: flex;
            flex-direction: column;
            height: auto;
            align-items: center;
            .left {
                order: 3;
                width: 100%;
                display: flex;
                flex-direction: column;
                height: auto;
                align-items: center;
                text-align: center;
                margin: 0;
                li {
                    margin: 0 0 var(--spacing-xs) 0;
                }
            }
            .logo {
                order: 1;
                width: 100%;
                background-position: center;
                margin: 0;
                margin: var(--spacing-xs) 0;
            }
            .right {
                order: 2;
                width: 100%;
                display: flex;
                flex-direction: column;
                height: auto;
                align-items: center;
                text-align: center;
                margin: 0;
                li {
                    margin: 0 0 var(--spacing-xs) 0;
                }
            }
         }   
        }
    }
    .container {
        padding: var(--spacing-xs);
    .row {
        nav {
            position: absolute;
            top: 0;
            right: 0;
            &.half {
                width: 100%;
            }
            &.active {
                width: 100%;
                height: 100%;
                background-color: white;
                color: black;
                position: fixed;
                z-index: 10;
            }
            & > .burger {
                margin: var(--spacing-xs);
                background-position: 50% 56%;
                background-size: 500%;
            }
            & > .menu {
                padding: var(--spacing-s);
                padding-top: 50%;
                top: 50vw;
                text-align: center;
                background-color: white;
                color: black;
                width: 100%;
                position: fixed;
                z-index: 11;
                top: var(--spacing-l);
                line-height: 2;
                & > * {
                    z-index: 12;
                }
            }
        }
    }
}

}