    .account {
        width: 300px;
        height: 570px;
        background-color: white;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-top: var(--spacing-m);
        // padding: var(--spacing-m) var(--spacing-m);
        h1 {
            width: 100%;
            text-align: center;
            padding: var(--spacing-xs) var(--spacing-m);
            margin-bottom: var(--spacing-s);
            font-family: futura-pt, sans-serif;
            font-weight: 700;
        }
        .input-group {
            display: inline-flex;
            font-size: var(--font-s);
            width: 100%;
            margin: var(--spacing-xs) 0;
            padding: var(--spacing-xs) var(--spacing-s);
            border: solid black 1px;
            color: black;
            text-transform: uppercase;
            & > label {
                line-height: 1.5;
                padding-top: 1px;
                padding-right: var(--spacing-xxs);
                white-space: nowrap;
                font-weight: 700;
            }
                & > input {
                    width: 100%;
                    border: none;
                    text-transform: uppercase;
                }
        }
        .error {
            margin-top: -15px;
        }
        .success {
            position: relative;
            top: 33%;
            transform: translateY(-50%);
        }
        .disclaimer {
            width: 100%;
            margin: 0;
            margin-top: var(--spacing-xs);
            p {
                margin: 0;
                font-size: var(--font-s);
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                    border: none;
                }
            }
        }
    }