* {
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: .1px;
    font-size: var(--font-m) default;
}

h1,
h2,
h3,
h4 {
    font-family: futura-pt-bold, sans-serif;
    font-style: normal;
    text-transform: uppercase;
    margin: 0;
}

h1 {
    font-size: var(--font-xl);
    font-weight: 700;
}

h2 {
    font-size: var(--font-l);
}

h3, h4 {
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-size: var(--font-m);
}

a {
    color: inherit;
    letter-spacing: .2px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
        border-bottom: solid 2px;
    }
}

p {
    font-size: var(--font-m);
    text-align: justify;
    a {
        text-transform: none;
    }
}

ul {
    padding: 0;
    margin: 0;
    height: 100%;
    li {
        list-style-type: none;
    }
}

em {
    font-style: italic;
    font-size: var(--font-s);
}


@media (max-width: 980px) {
/*     h1 {
        font-size: var(--font-xxl);
    }
    
    h2 {
        font-size: var(--font-xl);
    }
    
    h3, h4 {
        font-family: futura-pt, sans-serif;
        font-weight: 500;
        font-size: var(--font-l);
    }

    p {
        font-size: var(--font-l);
    } */
}

@media (max-width: 767px) {
    h1 {
        font-size: var(--font-xl);
    }
    
    h2 {
        font-size: var(--font-xl);
    }
    
    h3, h4 {
        font-family: futura-pt, sans-serif;
        font-weight: 500;
        font-size: var(--font-m);
    }

    p, ul, li {
        font-size: var(--font-l);
    }

    em {
        font-size: var(--font-m);
    }
}

input {
    line-height: 1.5;
}