.hero {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  & > .poster {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 200ms;
    &.active {
      opacity: 1;
    }
  }
  & > .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    & > .container {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &:hover {
        border: none;
      }
      .claim {
        font-size: var(--font-xxl);
        position: absolute;
        color: white;
        transform: translateY(-50%);
        top: 50%;
        width: calc(100% - var(--spacing-xl));
        text-align: center;
        &.black {
          color: black;
        }
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
    transition: 400ms;
  }
  &:hover {
    border: none;
    &::after {
      // opacity: .5;
    }
  }
}

@media (max-width: 767px) {
  .hero {
    & > .text {
      font-size: var(--font-s);
      h2 {
        font-size: var(--font-m);
      }
      & > .container {
        .claim {
          font-size: var(--font-m);
          width: calc(100% - var(--spacing-s));
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0;
      transition: 400ms;
    }
    &:hover {
      border: none;
      &::after {
        // opacity: .5;
      }
    }
  }
}
