.contact {
    margin-top: var(--spacing-xl);
    padding: var(--spacing-xl);
    width: 100%;
    text-align: center;
    height: 67vh;
    & > .contents {
        p {
            text-align: center;
        }
    }
}