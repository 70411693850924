.item {
    .headings {
        width: 50%;
        & > h1 {
            font-size: var(--font-xxl);
        }
        & > h1, h3 {
            text-transform: none;
            border-bottom: solid black 1px;
            margin-bottom: var(--spacing-xs);
            line-height: 1.2;
        }
        & > h2 {
            text-transform: none;
            font-family: futura-pt, sans-serif;
            font-weight: 500;
            font-size: var(--font-xl);
        }
        & > h3 {
            padding-bottom: var(--spacing-xs);
        }
    }
    .text {
        margin-left: 50%;
    }
}


@media (max-width: 980px) {
    .item {
        .row {
            .half {
                width: calc(100% - var(--spacing-l)) !important;
                margin: 0;
            }
        }
        .headings,
        .text {
            width: calc(100% - var(--spacing-l));
        }
    }
}

@media (max-width: 767px) {
    .item {
        .row {
            .half {
                width: calc(100%) !important;
                margin: 0;
            }
        }
        .headings,
        .text {
            width: calc(100%);
            & > h1, h3 {
                margin-bottom: var(--spacing-xxs);
            }
            & > h3 {
                padding-bottom: var(--spacing-xxs);
            }
        }
    }
}