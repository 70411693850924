.text-image {
    &-container {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-l);
        margin: var(--spacing-l) 0;
    }
    &-layout {
        display: flex;
        gap: var(--spacing-l);
        flex-direction: row;
        .contents, .responsive-image {
            width: 50%;
            // height: 300px;
            aspect-ratio: 16/9;
        }
        .responsive-image {
            overflow: hidden;
            img {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &.reverse {
            flex-direction: row-reverse;
        }
    }
}


@media (max-width: 767px) {
    .text-image {
        &-layout {
            flex-direction: column !important;
            .contents {
                aspect-ratio: unset;
            }
            .contents, .responsive-image {
                width: 100%;
                height: auto;
                img {
                    transform: none;
                    top: 0;
                }
            }
        }
    }    
}