.card-text {
    color: white;
    text-align: right;
    position: absolute;
    padding: inherit;
    bottom: 0;
    right: 0;
    &.black {
        color: black;
    }
}