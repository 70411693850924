.exhibitions {
    position: relative;
    margin-top: -125px;
    display: grid;
    gap: var(--spacing-s);
    grid-template-columns: repeat(2, 1fr);
    top: 0;
    left: 0;
    width: 100%;
}

@media (max-width: 980px) {
    .exhibitions {
        display: block;
        & > * {
            margin-bottom: var(--spacing-s);
        }
    }
}

@media (max-width: 767px) {
    .exhibitions {
        display: block;
        margin-top: -60px;
        & > * {
            margin-bottom: var(--spacing-xs);
        }
    }
}