.list-item {
  position: relative;
  // min-height: 28vw;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  padding-top: 50%; /* Player ratio: 100 / (1280 / 720) */
  & > .poster {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 200ms;
    top: 0;
    overflow: hidden;
    z-index: 2;
    &.active {
      opacity: 1;
    }
  }
  & > .player {
    position: absolute;
    top: 0;
    left: 0;
  }
  & > .text {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--spacing-s) var(--spacing-m);
    &:hover {
      border: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
    transition: 400ms;
  }
  &:hover {
    border: none;
    &::after {
      // opacity: .5;
    }
  }
}

@media (max-width: 767px) {
  .list-item {
    & > .text {
      padding: var(--spacing-xs) var(--spacing-xs);
      font-size: var(--font-s);
      h2 {
        font-size: var(--font-m);
      }
    }
  }
}