.vr-player {
  position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  padding-top: 50%;

  .player,
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay {
    cursor: pointer;
    .cta {
      width: 100%;
      text-align: center;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 4em;
        height: 4em;
        background-image: url('../../../assets/img/play.svg');
        background-size: 90%;
        background-repeat: no-repeat;
        z-index: 3;
      }
      h3 {
        position: absolute;
        top: calc(50% + 2.5em);
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: var(--font-xxl);
        border-bottom: solid 2px;
        z-index: 3;
        &.black {
          color: black;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0;
      transition: 400ms;
    }
    &:hover {
      border: none;
      &::after {
        opacity: .5;
      }
    }
  }

}

@media (max-width: 767px) {
  .vr-player {
    .overlay {
      .cta {
        width: 100%;
        text-align: center;
        h3 {
          font-size: var(--font-l);
        }
      }
    }
  }
}